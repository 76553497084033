import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["template"];

  initialize() {
    window.onkeydown = function(event) {
      if (event.key == "Shift") {
        this.disableArrows = true;
      }
    }.bind(this);

    window.onkeyup = function(event) {
      if (event.key == "Shift") {
        this.disableArrows = false;
      }
    }.bind(this);
  }

  updateInputFieldList() {
    this.inputFields = this.measurementForm.querySelectorAll(".measurement_input_field");

    this.inputFields.forEach(
      function(inputField) {
        inputField.onfocus = function(event) {
          this.inputFields.forEach(f => {
            const inputGroup = f.closest(".input-group");
            if (inputGroup) {
              inputGroup.classList.remove("highlighted");
            }
          });
          const inputGroup = event.target.closest(".input-group");
          if (inputGroup) {
            inputGroup.classList.add("highlighted");
          }
          if (event.target.select) {
            event.target.select();
          }
        }.bind(this);
      }.bind(this)
    );
  }

  connect() {
    this.measurementForm = document.querySelector("#measurement-collection-form");
    this.index = this.measurementForm.querySelectorAll(".measurements--measurement").length + 1;
    if (this.element.querySelector(".measurements--measurements").children.length == 0) {
      this.addNewMeasurement({ scrollToElement: false });
    }
    this.updateInputFieldList();
  }

  addNewMeasurementEvent(event) {
    event.preventDefault();
    this.addNewMeasurement({ scrollToElement: true });
  }

  addNewMeasurement({ scrollToElement }) {
    const template = this.templateTarget;
    const newMeasurementForm = document.importNode(template.content, true);
    const wrapper = newMeasurementForm.querySelector(".measurements--measurement");

    wrapper.id = wrapper.id.replace(/{{index}}/gi, this.index);
    wrapper.innerHTML = wrapper.innerHTML.replace(/{{index}}/gi, this.index);
    this.index++;
    wrapper.dataset.new_record = true;
    this.element.querySelector(".measurements--measurements").appendChild(newMeasurementForm);

    // Scroll newly added measurement column into view
    if (scrollToElement) {
      const measurement = document.querySelector(".measurements--measurements").lastChild;
      measurement.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      let title = measurement.querySelector(
        "#measurement_collection_measurements_attributes___index___title"
      );
      title.select();
      title.focus();
    }

    this.updateInputFieldList();
  }

  removeMeasurementEvent(event) {
    event.preventDefault();
    const measurement = event.target.closest(".measurements--measurement");
    if (!window.confirm("Wollen Sie dieses Aufmaß wirklich löschen?")) {
      return;
    }
    if (measurement.dataset.new_record) {
      this.removeMeasurement(measurement);
    } else {
      this.deactivateMeasurement(measurement);
    }
  }

  removeMeasurement(measurement) {
    measurement.parentNode.removeChild(measurement);
  }

  deactivateMeasurement(measurement) {
    measurement.classList.add("removed");
    measurement.querySelector(".js-destroy-measurement").value = true;
  }

  undoRemoveMeasurementEvent(event) {
    event.preventDefault();
    const measurement = event.target.closest(".measurements--measurement");
    this.undoRemoveMeasurement(measurement);
  }

  undoRemoveMeasurement(measurement) {
    measurement.querySelector(".js-destroy-measurement").value = false;
    measurement.classList.remove("removed");
  }

  manage_keypress(event) {
    switch (event.key) {
      case "ArrowDown":
        if (!this.disableArrows) {
          event.preventDefault();
          this.selectNextInputField(event.target);
        }
        break;
      case "ArrowUp":
        if (!this.disableArrows) {
          event.preventDefault();
          this.selectPreviousInputField(event.target);
        }
        break;
      // default:
      //   console.info(event.key);
    }
  }

  selectNextInputField(currentInputField) {
    const currentFieldIndex = Array.prototype.slice
      .call(this.inputFields)
      .findIndex(f => f == currentInputField);

    if (currentFieldIndex < this.inputFields.length - 1) {
      this.inputFields[currentFieldIndex + 1].focus();
    }
  }

  selectPreviousInputField(currentInputField) {
    const currentFieldIndex = Array.prototype.slice
      .call(this.inputFields)
      .findIndex(f => f == currentInputField);

    if (currentFieldIndex > 0) {
      this.inputFields[currentFieldIndex - 1].focus();
    }
  }
}
