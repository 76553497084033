import $ from "jquery";
import * as Mathjs from 'mathjs';

const measurementCalculations = () => {
  $(".measurements--measurements").on('input', 'td.equation-input-group', function () {
    let roundedResult = calculateResultForThisRow($(this))
    fillResultFieldOfThisRow($(this), roundedResult)
    calculateSum($(this))
  });

  $(".measurements--measurements").on('click', '.lock-values', function () {
    fillAndDisableRegularInputField($(this))
    FillHiddenFields($(this))
  });

  $(".measurements--measurements").on('click', '.clear-values', function () {
    clearAndEnableRegularInputField($(this))
  });

  $(".measurements--measurements").on('click', 'i.fa-trash-alt', function () {
    removeClickedRowAndRecalculate($(this));
  });

  $(".measurements--measurements").on('input', '.measurement_input_field', function () {
    writeInputIntoHiddenField($(this))
  });

  $(".measurements--measurements").on('click', 'i.fa-plus-circle', function () {
    addNewRowAndIcons($(this))
  });

  $(".measurements--measurements").on('click', '.display-calculator-form', function ( event ) {
    if(event.target.className.includes('display-calculator-form')) {
      displayCalculator($(this), event);
    }
    event.stopPropagation();
  });

  $(document).click(function() {
    // hide calculator when clicked outside of it (works for all areas except spec type units not belonging to the currently visible calculator)
    $('.calculator-form.visible').addClass('hidden').removeClass('visible');
  });

  function displayCalculator(specTypeUnit, event) {
    var amountVisibleCalculators = $('.calculator-form.visible').length;

    if(amountVisibleCalculators==0){
      if(specTypeUnit.find('.calculator-form').length>0){
        specTypeUnit.find('.calculator-form').removeClass('hidden').addClass('visible');
      };
    };

    // display calculator when clicking on a spec unit which is not the spec unit of the currently visible calculator
    if(amountVisibleCalculators==1){
      if($('.calculator-form.visible').parents('.display-calculator-form').attr('class')!==specTypeUnit.attr('class') || ($('.calculator-form.visible').parents('.display-calculator-form').attr('class')===specTypeUnit.attr('class')) && $('.calculator-form.visible').parents('.measurements--measurement').attr('id')!==specTypeUnit.parents('.measurements--measurement').attr('id')){
        $('.calculator-form.visible').removeClass('visible').addClass('hidden');
        specTypeUnit.find('.calculator-form').removeClass('hidden').addClass('visible');
      };
    };
  };

  function writeInputIntoHiddenField(regularInputField) {
    let input_group = regularInputField.parents('.input-group');
    let spec_type = input_group.attr('spec_type');
    let input_value = regularInputField.val();
    input_group.find('.hidden-input-field.value.'+spec_type).attr('value', input_value);
    input_group.find('.hidden-input-field.equations.'+spec_type).attr('value', '');
  };

  function calculateResultForThisRow(equationInputGroup) {
    let equationRow = equationInputGroup.parents('tr.equation-row')
    let currentEquationWithVariables = equationRow.attr("equation");
    let currentEquation = currentEquationWithVariables.split(": ")[0]
    let equationVariables = currentEquationWithVariables.split(": ")[1].split(", ")
    let equationInputFields = equationRow.find('.table-cell-input-group')

    let inputValues = getInputValues(equationInputFields)
    let scope = populateMathjsScope(equationVariables, inputValues)

    let result = Mathjs.evaluate(currentEquation, scope);
    let roundedResult = parseFloat(Number(result).toFixed(3));

    return roundedResult
  };

  function fillResultFieldOfThisRow(equationInputGroup, roundedResult) {
    let resultField = equationInputGroup.parents('tr.equation-row').find('.result-value');
    resultField.text(roundedResult.toString().replace(/\./g, ','));
  };

  function getInputValues(equationInputFields) {
    let inputValues = {}

    equationInputFields.each (function() {
      let label = $(this).find('.equation-label-field').attr('variable');
      let value = Number($(this).find('.equation-input-field').val()).toFixed(3);
      inputValues[label] = value;
    });

    return inputValues
  };

  function populateMathjsScope(equationVariables, inputValues) {
    let scope = {}
    let index = 0

    equationVariables.forEach (element => {
      scope[element] = inputValues[element]
      index++;
    });

    return scope
  };

  function calculateSum(equationInputGroup) {
    let sumField = equationInputGroup.parents('.calculator-form').find('.sum-value');
    let allEquationRows = equationInputGroup.parents('.calculator-form').find('tr.equation-row')
    let newSum = getNewSum(allEquationRows)
    let newRoundedSum = parseFloat(Number(newSum).toFixed(3));
    sumField.text(newRoundedSum.toString().replace(/\./g, ','));
  }

  function FillHiddenFields(lockButton) {
    let calculatorForm = lockButton.parents('.calculator-form');
    let hiddenValueField = calculatorForm.siblings('.hidden-input-field.value');
    let equationRows = calculatorForm.find("tr.equation-row")
    let hiddenResultsFields = calculatorForm.siblings('.hidden-input-field.equations');

    hiddenResultsFields.each (function() {
      $(this).attr('value', '')
    })

    equationRows.each (function() {
      let equationRow = $(this)
      let equationName = equationRow.attr('equation-name')
      let newResultsArray = getNewResults(equationRow)
      let thisEquationsHiddenResultsField = calculatorForm.siblings('.hidden-input-field.equations.'+equationName)
      thisEquationsHiddenResultsField.appendAttr('value', newResultsArray)
    })

    let sumField = lockButton.parents('.calculator-form').find('.sum-value')
    hiddenValueField.attr('value', Number(sumField.text().replace(/\,/g, '.')).toFixed(3));
  }

  function getNewResults(equationRows) {
    let newResultsArray = new Array();
    equationRows.each (function() {
      let thisResult = Number($(this).find('.result-value').text().replace(/\,/g, '.')).toFixed(3);
      if(!isNaN(thisResult)){ // reject empty strings
        let resultString = '{"result":"'+thisResult+'",';
        let valueString = "";
        // loop through input boxed per row
        $(this).find('.table-cell-input-group').each (function() {
          let label = $(this).find('.equation-label-field').attr('variable');
          let input = Number($(this).find('.equation-input-field').val()).toFixed(3);
          valueString = valueString+'"'+label+'":"'+input+'",';
        });
        valueString = valueString.slice(0,-1);
        let closingString = '}'
        let separatorString = ',';
        newResultsArray.push(resultString+valueString+closingString+separatorString);
      }
    });
    return newResultsArray
  };

  function getNewSum(allEquationRows) {
    let newSum = 0;

    allEquationRows.each (function() {
      let result = $(this).find('.result-value').text().replace(/\,/g, '.');
      if(!isNaN(result)){
        newSum += parseFloat(result);
      };
    });

    return newSum
  };

  function fillAndDisableRegularInputField(clickedButton) {
    let sumField = clickedButton.parents('.calculator-form').find('.sum-value')
    let regularInputField = clickedButton.parents('.input-group').find('.measurement_input_field')
    regularInputField.prop("disabled", true);
    regularInputField.val(Number(sumField.text().replace(/\,/g, '.')).toFixed(2))
  }

  function clearAndEnableRegularInputField(clickedButton) {
    let regularInputField = clickedButton.parents('.input-group').find('.measurement_input_field')
    let hiddenInputFields = clickedButton.parents('.input-group').find('.hidden-input-field')

    hiddenInputFields.each (function() {
      let hiddenInputField = $(this)
      hiddenInputField.attr('value', '')
    })

    regularInputField.prop("disabled", false);
    regularInputField.val('');
  }

  function removeClickedRowAndRecalculate(clickedTrashIcon) {
    let calculatorForm = clickedTrashIcon.parents('.calculator-form');
    let clickedRow = clickedTrashIcon.parents('tr.equation-row');
    let equationName = clickedRow.attr('equation-name');
    let equationRows = calculatorForm.find("[equation-name='"+equationName+"']");
    let removedResult = clickedRow.find('.result-value').text().replace(/\,/g, '.');
    let sumField = clickedRow.parents('.calculator-table').find('.sum-value');
    let previousSum = parseFloat(sumField.text().replace(/\,/g, '.'));

    // after clicking on trash icon: calculate the new sum of all results and populate the hidden fields with the new values
    let { newRoundedSum, newResultsArray } = getNewSumAndResults(equationRows, clickedRow, removedResult, previousSum);

    // removes trailing zeros
    let displayNewRoundedSum = parseFloat(newRoundedSum);
    sumField.text(displayNewRoundedSum.toString().replace(/\./g, ','));

    // write input values and sum of all rows into hidden fields for submission
    let hiddenValueField = calculatorForm.siblings('.hidden-input-field.value');
    let thisEquationsHiddenResultsField = calculatorForm.siblings('.hidden-input-field.equations.'+equationName);
    thisEquationsHiddenResultsField.attr('value', newResultsArray);
    hiddenValueField.attr('value', newRoundedSum);

    // removes row in which trash icon was clicked on
    clickedRow.remove();

    // if there does not exist an add row icon in the last row for this equation: add one
    let addRowIconPresent = calculatorForm.find("[equation-name='"+equationName+"']:last").find('td.icons').find('i.fa-plus-circle').length;
    if (addRowIconPresent == 0) {
      addPlusIcon(calculatorForm, equationName);
    };

    // if there is left only one row for this equation: remove trash icon - it should not be possible to remove this row
    let amountOfRowsForThisEquation = calculatorForm.find("[equation-name='"+equationName+"']").length;
    if (amountOfRowsForThisEquation == 1) {
      removeTrashIcon(calculatorForm, equationName);
    };
  };

  function getNewSumAndResults(equationRows, clickedRow, removedResult, previousSum) {
    let newResultsArray = new Array();
    equationRows.each (function() {
      let thisRow = $(this);
      let thisResult = Number($(this).find('.result-value').text().replace(/\,/g, '.')).toFixed(3);
      if(thisResult){ // reject empty strings
        if(thisRow.get(0)!==clickedRow.get(0)){
          let resultString = '{"result":"'+thisResult+'",';
          let valueString = "";
          // loop through all input boxes of this row
          $(this).find('.table-cell-input-group').each (function() {
            let label = $(this).find('.equation-label-field').attr('variable');
            let input = Number($(this).find('.equation-input-field').val()).toFixed(3);
            valueString = valueString+'"'+label+'":"'+input+'",';
          });
          valueString = valueString.slice(0,-1);
          let closingString = '}'
          let separatorString = ',';
          newResultsArray.push(resultString+valueString+closingString+separatorString);
        };
      };
    });
    let newRoundedSum = Number(previousSum-parseFloat(removedResult)).toFixed(3);

    return { newRoundedSum, newResultsArray }
  };

  function removeTrashIcon(calculatorForm, equationName) {
    let iconsCell = calculatorForm.find("[equation-name='"+equationName+"']:last").find('td.icons');
    iconsCell.find('i.fa-trash-alt').remove();
  };

  function addPlusIcon(calculatorForm, equationName) {
    let iconsCell = calculatorForm.find("[equation-name='"+equationName+"']:last").find('td.icons');
    // add plus icon before trash icon
    iconsCell.find('i.fa-trash-alt').before(plusIconHtml);
  };

  function addNewRowAndIcons(addRowIcon) {
    let clickedRow = addRowIcon.parents('tr.equation-row')

    addTrashIconToClickedRow(addRowIcon)
    let clonedRow = cloneClickedRow(clickedRow);

    // inserts cloned row
    clickedRow.after(clonedRow);

    // adds trash icon if not present in cloned row
    if (clonedRow.find('td.icons').find('i.fa-trash-alt').length == 0) {
      $('i.fa-plus-circle', clonedRow).after(trashIconHtml)
    }

    // removes plus icon from clicked row
    addRowIcon.remove();
  };

  function addTrashIconToClickedRow(addRowIcon) {
    let removeRowIcon = addRowIcon.parents('td.icons').find('i.fa-trash-alt');
    if (removeRowIcon.length == 0) {
      addRowIcon.after(trashIconHtml)
    };
  };

  function cloneClickedRow(clickedRow) {
    let clonedRow = clickedRow.clone();
    $('.result-value', clonedRow).text('-');
    $('td.equation-input-group', clonedRow).find('.equation-input-field').each  (function() {
      $(this).val('');
    });

    return clonedRow;
  };

  let plusIconHtml = '<i class="icon far fa-plus-circle" aria-hidden="true" style="width: 50%; margin:auto"></i>'
  let trashIconHtml = '<i class="icon far fa-trash-alt" aria-hidden="true" style="width: 50%; margin:auto"></i>'

  $.fn.appendAttr = function(attrName, suffix) {
    this.attr(attrName, function(i, val) {
      return val + suffix;
    });
    return this;
  };
};

export default measurementCalculations;