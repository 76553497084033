// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import "@hotwired/turbo-rails"
Turbo.session.drive = false
import Rails from "@rails/ujs";
Rails.start();

import "./controllers"

import "../stylesheets/application.scss";
import "popper.js";
import "bootstrap/js/dist/util";
import "bootstrap/js/dist/alert";
import "bootstrap/js/dist/tooltip";
import "bootstrap/js/dist/dropdown";
import "bootstrap-datepicker/dist/js/bootstrap-datepicker";
import "bootstrap-datepicker/dist/css/bootstrap-datepicker.css";

// import image files
const images = require.context('../images', true)

// define turbo events
const turboEvents = ["turbo:frame-load", "turbo:frame-render", "turbo:load", "turbo:render", "turbo:visit", "turbo:before-visit", "turbo:before-fetch-response", "turbo:submit-start", "turbo:submit-end", "turbo:before-render", "turbo:before-render-request", "turbo:before-cache", "turbo:before-stream-render", "turbo:frame-load", "turbo:frame-render", "turbo:frame-missing", "turbo:before-frame-render", "turbo:fetch-request-error"];

// Support component names relative to this directory:
var componentRequireContext = require.context("./components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

// make sure react components are mounted after turbo events are fired
turboEvents.forEach((event) => {
  ReactRailsUJS.handleEvent(event, ReactRailsUJS.handleMount)
});

import initMeasurementCollectionControls from "./packs/measurement_collections";
import initMeasurementCalculations from "./packs/measurement_calculations";
import initBillableHours from "./packs/billable_hours";
import initMaterialExpenses from "./packs/material_expenses";

const ready = () => {
  initFlashMessages();
  initTooltips();
  initMeasurementCollectionControls();
  initMeasurementCalculations();
  initBillableHours();
  initMaterialExpenses();
};

// make sure all the above functions are called after turbo events are fired
turboEvents.forEach((event) => {
  $(document).on(event, ready);
});

const initTooltips = () => {
  // Initialize Bootstrap tooltips
  $('[data-toggle="tooltip"]').tooltip();
};

const initFlashMessages = () => {
  // Initialize Bootstrap alerts
  $(".alert").alert();
};

// import custom turbo stream actions
import "./packs/turbo_stream_actions"

// import custom helpers
import "./packs/helpers.js"