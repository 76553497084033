import $ from "jquery"
import * as Mathjs from 'mathjs'

const billableHours = () => {
  $('.embedded-form').on('click', function(){
    callPriceCalculator()
  })

  $("#billable_hour_amount_of_hours").on('input', function () {
    callPriceCalculator()
  });

  function callPriceCalculator() {
    $('#billable_hour_price').attr('amount_of_hours', $("#billable_hour_amount_of_hours").val())
    calculatePrice()
  }
}

export function calculatePrice() {
  let amount_of_hours = $('#billable_hour_amount_of_hours').val()
  let selected_article_price = $('#billable_hour_price').attr('selected_article_price')
  if(amount_of_hours!="" && selected_article_price!=undefined){
    let billable_hour_price = Mathjs.multiply(amount_of_hours, selected_article_price)
    billable_hour_price = Number(billable_hour_price).toFixed(2)
    $('#billable_hour_price').attr('value', billable_hour_price)
  }else{
    $('#billable_hour_price').attr('value', "")
  }
}

export default billableHours