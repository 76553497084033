import React from "react";
import Select, { components } from "react-select";
import $ from "jquery";
import { calculatePrice as calculateBillableHoursPrice } from "../packs/billable_hours"
import { calculatePrice as calculateMaterialExpensesPrice } from "../packs/material_expenses"

// this generates the text displayed in the dropdown list of articles
const Option = props => {
  const article = props.data;
  const model = props.selectProps.model;

  let articleCurrentPrice = getNewArticlePrice(article, props.selectProps.referenceDate);

  if (model == "billable_hours") {
    return (
      <components.Option {...props}>
        <i className="far fa-regular fa-clock" />{" "} {article.description}
        <div>
          <small>
          <span title="Kaefer Code" className="mr-3">
            <i className="far fa-box-open" /> {article.kaefer_code || "–"}
          </span>
          <span title="iScala Nummer" className="mr-3">
            <i className="far fa-solid fa-hashtag" /> {article.iscala_number || "–"}
          </span>
          <span title="Aktueller Preis" className="mr-3">
            <i className="far fa-solid fa-money-bill" /> {articleCurrentPrice + " €" || "–"}
          </span>
          </small>
        </div>
      </components.Option>
    );
  } else if (model == "material_expenses") {
    return (
      <components.Option {...props}>
        <i className="far fa-ball-pile" />{" "} {article.description}
        <div>
          <small>
          <span title="Kaefer Code" className="mr-3">
            <i className="far fa-box-open" /> {article.kaefer_code || "–"}
          </span>
            <span title="iScala Nummer" className="mr-3">
            <i className="far fa-solid fa-hashtag" /> {article.iscala_number || "–"}
          </span>
            <span title="Aktueller Preis" className="mr-3">
            <i className="far fa-solid fa-money-bill" /> {articleCurrentPrice + " €" || "–"}
          </span>
          </small>
        </div>
      </components.Option>
    );
  };
};

// this generates the text displayed in the select field after an article has been selected
const SingleValue = props => {
  const article = props.data;
  const model = props.selectProps.model;

  let icon = null;
  let referenceDate = props.selectProps.referenceDate;
  let articleCurrentPrice = getNewArticlePrice(article, referenceDate);

  if (model == "billable_hours") {
    icon = "far fa-regular fa-clock";
  } else if (model == "material_expenses") {
    icon = "far fa-ball-pile";
  };

  return (
    <components.SingleValue {...props}>
      <i className={icon} />{" "} {article.description + " - " + articleCurrentPrice.replace(/\./g, ',') + " €"}
    </components.SingleValue>
  );
};

class PickerTemplate extends React.Component {
  state = {
    selectedArticle: this.props.articles.find(article => article.id === this.props.selectedArticleId),
    referenceDate: this.props.referenceDate,
    selectedArticleCurrentPrice: null
  };

  componentDidUpdate(prevProps) {
    if (this.props.referenceDate !== prevProps.referenceDate) {
      this.handleReferenceDateChange(this.props.referenceDate);
    }
  }

  handleReferenceDateChange = newReferenceDate => {
    this.setState({ referenceDate: newReferenceDate });

    const { selectedArticle } = this.state;

    if (selectedArticle) {
      let selectedArticleCurrentPrice = getNewArticlePrice(selectedArticle, newReferenceDate, this.state.selectedArticleCurrentPrice, this.props.model);

      if (this.props.model === "billable_hours") {
        $('#billable_hour_price').attr('selected_article_price', selectedArticleCurrentPrice);
        calculateBillableHoursPrice();
      } else if (this.props.model === "material_expenses") {
        $('#material_expense_price').attr('selected_article_price', selectedArticleCurrentPrice);
        calculateMaterialExpensesPrice();
      }

      if (this.state.selectedArticleCurrentPrice !== selectedArticleCurrentPrice) {
        this.setState({ selectedArticleCurrentPrice: selectedArticleCurrentPrice });
      }
    }
  };

  handleChange = selectedArticle => {
    this.setState({ selectedArticle });

    let selectedArticleCurrentPrice = getNewArticlePrice(selectedArticle, this.props.referenceDate);

    if (this.props.model == "billable_hours") {
      $('#billable_hour_price').attr('selected_article_price', selectedArticleCurrentPrice)
      calculateBillableHoursPrice();
    } else if (this.props.model == "material_expenses") {
      $('#material_expense_price').attr('selected_article_price', selectedArticleCurrentPrice)
      calculateMaterialExpensesPrice();
    };
  };

  // Builds a searchable string for each article, used to compare options against the search input
  getOptionLabel = article => {
    if (!article) {
      return null;
    }

    let referenceDate = this.props.referenceDate;
    let articleCurrentPrice = getNewArticlePrice(article, referenceDate);

    return [
      article.description,
      article.kaefer_code,
      article.iscala_number,
      articleCurrentPrice
    ].join(" ");
  };

  // Sets the ArticleId is set on the HTML input for form submission
  getOptionValue = article => article.id;

  render() {
    const { articles } = this.props;
    const { selectedArticle } = this.state;
    const { model } = this.props;
    const { referenceDate } = this.state;
    let selectId = null;
    let selectPlaceholderText = null;
    let selectInputId = null;
    let selectNoOptionsMessage = null;
    let labelHtmlFor = null;
    let labelContent = null;

    function modelSpecificStrings(){
      if (model == "billable_hours") {
        selectId = "hourPicker";
        selectPlaceholderText = "Tarif wählen…";
        selectInputId = "hour_picker";
        selectNoOptionsMessage = "Keinen Tarif gefunden.";
        labelHtmlFor = "hout_picker";
        labelContent = "Tarif";
      } else if (model == "material_expenses") {
        selectId = "materialPicker";
        selectPlaceholderText = "Material wählen…";
        selectInputId = "material_picker";
        selectNoOptionsMessage = "Keine Materialien gefunden.";
        labelHtmlFor = "material_picker";
        labelContent = "Material";
      };
    };

    modelSpecificStrings();

    if (selectedArticle) {
      let referenceDate = this.state.referenceDate;
      let selectedArticleCurrentPrice = getNewArticlePrice(selectedArticle, referenceDate, this.state.selectedArticleCurrentPrice, model);

      if (model == "billable_hours") {
        $('#billable_hour_price').attr('selected_article_price', selectedArticleCurrentPrice);
        $('#billable_hour_price').attr('amount_of_hours', $("#hour_amount_of_hours").val());
      } else if (model == "material_expenses") {
        $('#material_expense_price').attr('selected_article_price', selectedArticleCurrentPrice);
        $('#material_expense_price').attr('amount_of_material', $("#amount_of_material").val());
      };
    };

    return (
      <>
        <label className="form-control-label select optional" htmlFor={labelHtmlFor}>
          {labelContent}
        </label>
        <Select
          isSearchable
          isClearable
          isDisabled={this.props.isDisabled}
          options={articles}
          name={this.props.htmlInputName}
          value={selectedArticle}
          id={selectId}
          model={model}
          getOptionLabel={this.getOptionLabel}
          getOptionValue={this.getOptionValue}
          onChange={this.handleChange}
          components={{ Option, SingleValue }}
          placeholder={selectPlaceholderText}
          noOptionsMessage={() => {selectNoOptionsMessage}}
          inputId={selectInputId}
          referenceDate={referenceDate}
          ref={select => (window.hourPickerSelect = select)}
        />
      </>
    );
  }
}

function getNewArticlePrice(article, referenceDate = getCurrentDate(), selectedArticleCurrentPrice = null, model = null) {
  let newPrice = null;

  if (article.price_changes.length !== 0) {
    // loops through all price changes and sets the price to the value of the latest price change that is older than the reference date
    for (let i = article.price_changes.length - 1; i >= 0; i--) {
      let priceChange = article.price_changes[i].attributes
      let priceChangeStartDate = new Date(priceChange.start_date).setHours(0, 0, 0, 0);
      let formattedReferenceDate = new Date(referenceDate).setHours(0, 0, 0, 0);

      if (formattedReferenceDate >= priceChangeStartDate) {
        newPrice = priceChange.value;

        break
      }
    }

    if (!newPrice) {
      newPrice = article.default_price;
    }

    if (selectedArticleCurrentPrice) {
      if (selectedArticleCurrentPrice !== newPrice) {
        if (model === "billable_hours") {
          flashElement('billable-hours-article-picker');
        } else if (model === "material_expenses") {
          flashElement('material-expenses-article-picker');
        }
      }
    }

    return newPrice;
  } else {
    if (selectedArticleCurrentPrice) {
      if (selectedArticleCurrentPrice !== article.default_price) {
        if (model === "billable_hours") {
          flashElement('billable-hours-article-picker');
        } else if (model === "material_expenses") {
          flashElement('material-expenses-article-picker');
        }
      }
    }

    return article.default_price;
  }
}

function getCurrentDate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(today.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

function flashElement(elementId) {
  const element = document.getElementById(elementId);
  if (element) {
    element.classList.add('flash-highlight');
    setTimeout(() => {
      element.classList.remove('flash-highlight');
    }, 1000); // Duration of the flash effect
  }
}

export default PickerTemplate;