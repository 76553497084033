document.addEventListener("DOMContentLoaded", function() {
  var submitButtonMaterialExpensesForm = document.getElementById("material-expenses-embedded-form-submit-button");

  if (submitButtonMaterialExpensesForm != null) {
    submitButtonMaterialExpensesForm.addEventListener("click", function () {
      var newMaterialExpenseForm = document.getElementById("embedded-material-expenses-form");
      var validationErrors = newMaterialExpenseForm.querySelectorAll(".invalid-feedback");
      var flashMessages = newMaterialExpenseForm.querySelectorAll(".form-errors");

      validationErrors.forEach(function(element) {
        element.remove();
      });
      flashMessages.forEach(function(element) {
        element.remove();
      });
    });
  }

  var billableHoursFormSubmitButton = document.getElementById("billable-hours-embedded-form-submit-button");
  if (billableHoursFormSubmitButton != null) {
    billableHoursFormSubmitButton.addEventListener("click", function () {
      var newBillableHourForm = document.getElementById("embedded-billable-hours-form");
      var validationErrors = newBillableHourForm.querySelectorAll(".invalid-feedback");
      var flashMessages = newBillableHourForm.querySelectorAll(".form-errors");

      validationErrors.forEach(function(element) {
        element.remove();
      });
      flashMessages.forEach(function(element) {
        element.remove();
      });
    });
  }
});