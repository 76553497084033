import $ from "jquery";

const measurementCollectionControls = () => {
  $("input[id^='measurement-collection-']").each((index, ms) => {
    ms.checked = true;
  });

  const header = $("#measurement-collection-actions")[0];
  let sticky;
  // Attach actions bar to the top at the exact moment it touches the
  // top of the window on scroll
  if (header) {
    sticky = header.offsetTop - header.offsetHeight * 2;

    window.onscroll = function() {
      attachSticky();
    };
  }
  const attachSticky = () => {
    if (window.pageYOffset > sticky) {
      header.classList.add("sticky");
    } else {
      header.classList.remove("sticky");
    }
  };

  const mSsubmitter = (url, openInNewWindow) => {
    return () => {
      const unselected = $.map($("input[id^='measurement-collection-']:not(:checked)"), el => {
        return el.value;
      });

      for (var i = 0; i < unselected.length; ++i) {
        if (url.indexOf("?") === -1) {
          url = url + "?measurement_collection_ids[]=" + unselected[i];
        } else {
          url = url + "&measurement_collection_ids[]=" + unselected[i];
        }
      }
      if (url.indexOf("?") === -1) {
        url = url + "?start_date=" + encodeURIComponent($("#start_date")[0].value);
        url = url + "&end_date=" + encodeURIComponent($("#end_date")[0].value);
      } else {
        url = url + "&start_date=" + encodeURIComponent($("#start_date")[0].value);
        url = url + "&end_date=" + encodeURIComponent($("#end_date")[0].value);
      }
      if (openInNewWindow) {
        window.open(url, "_blank");
      } else {
        window.location = url;
      }
    };
  };

  const controlGenerateBtnState = () => {
    if ($("input[id^='measurement-collection-']:checked").length == 0) {
      $("#generate-report").attr("disabled", true);
    } else {
      $("#generate-report").removeAttr("disabled");
    }
  };

  $("#generate-sar").on("click", mSsubmitter("/reports/generate_sar", true));
  $("#filter-sar").on("click", mSsubmitter("/reports/sar", false));
  $("#generate-report").on("click", mSsubmitter("/reports/generate_gar", true));
  $("#filter-report").on("click", mSsubmitter("/reports/gar", false));
  controlGenerateBtnState();

  $("input[id^='measurement-collection-']").on("click", e => {
    const currentGrandTotal = $("#measurement-collections-total")[0].innerHTML;
    const parentTableRow = $(e.target).closest("tr");
    const selectedTotal = parentTableRow.find("td").last()[0].innerHTML;
    const currencyOptions = {
      separator: ".",
      decimal: ",",
      precision: 2,
      symbol: "€",
      pattern: `# !`
    };

    if (e.target.checked) {
      parentTableRow.removeClass("measurement-collection-deselected");
    } else {
      parentTableRow.addClass("measurement-collection-deselected");
    }

    if (currentGrandTotal.indexOf("€") > -1 && selectedTotal.indexOf("€") > -1) {
      let currentTotal = currency(currentGrandTotal, currencyOptions);
      let selected = currency(selectedTotal, currencyOptions);
      let result;
      if (e.target.checked) {
        result = currentTotal.add(selected);
      } else {
        result = currentTotal.subtract(selected);
      }
      $("#measurement-collections-total")[0].innerHTML = result.format(true);
    }

    controlGenerateBtnState();
  });


  $(document).on('click', '.measurement_input_field', function () {
    let hiddenResultsField = $(this).parents('.input-group').children(".hidden-input-field");
    let measurement_input_field = $(this).val();
    hiddenResultsField.attr('value', measurement_input_field);
  });
};

export default measurementCollectionControls;
