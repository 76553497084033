import $ from "jquery"
import * as Mathjs from 'mathjs'

const materialExpenses = () => {
  $('.embedded-form').on('click', function(){
    callPriceCalculator()
  })

  $("#material_expense_amount_of_material").on('input', function () {
    callPriceCalculator()
  });

  function callPriceCalculator() {
    $('#material_expense_price').attr('amount_of_material', $("#material_expense_amount_of_material").val())
    calculatePrice()
  }
}

export function calculatePrice() {
  let amount_of_material = $('#material_expense_amount_of_material').val()
  let selected_article_price = $('#material_expense_price').attr('selected_article_price')
  if(amount_of_material!="" && selected_article_price!=undefined){
    let material_expense_price = Mathjs.multiply(amount_of_material, selected_article_price)
    material_expense_price = Number(material_expense_price).toFixed(2)
    $('#material_expense_price').attr('value', material_expense_price)
  }else{
    $('#material_expense_price').attr('value', "")
  }
}

export default materialExpenses