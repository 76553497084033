import { StreamActions } from "@hotwired/turbo"

StreamActions.append_validation_error_message = function() {
  const message = this.getAttribute("message")
  const target = this.getAttribute("target")
  const targetId = "#"+target

  $(targetId).append(`<div class="invalid-feedback" invalid-attribute="${target}" style="display: block">${message}</div>`)
}

StreamActions.prepend_flash_message = function() {
  const model = this.getAttribute("model")

  $('#embedded-form').prepend("<div class='form-errors'><div class='alert alert-danger'>Bitte korrigieren Sie die folgenden Felder:</div></div>")

  if(model === "billable-hours") {
    $('#embedded-billable-hours-form').prepend("<div class='form-errors'><div class='alert alert-danger'>Bitte korrigieren Sie die folgenden Felder:</div></div>")
  }

  if(model === "material-expenses") {
    $('#embedded-material-expenses-form').prepend("<div class='form-errors'><div class='alert alert-danger'>Bitte korrigieren Sie die folgenden Felder:</div></div>")
  }
}