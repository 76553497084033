import React from "react";
import Select, { components } from "react-select";

const Option = props => {
  const order = props.data;
  return (
    <components.Option {...props}>
      <i className={`fas fa-fw ${order.collective_order ? "fa-cubes" : "fa-cube"}`} />{" "}
      {order.order_title}
      <div>
        <small>
          <span title="Auftragsstatus" className="mr-3">
            <i
              className={`far fa-fw ${
                order.state == "closed" ? "fa-shield-check" : "fa-shield-alt"
              }`}
            />
            {order.humanized_state}
          </span>
          <span title="Auftragsdatum" className="mr-3">
            <i className="far fa-fw fa-calendar-alt mr" /> {order.order_date}
          </span>
          <span title="Erfasser" className="mr-3">
            <i className="far fa-fw far fa-user-crown" /> {order.recorder || "–"}
          </span>
          {order.collective_order && (
            <span title="Sammelauftrag">
              <i className="fas fa-fw far fa-cubes" /> Sammelauftrag
            </span>
          )}
        </small>
      </div>
    </components.Option>
  );
};

const SingleValue = props => {
  const order = props.data;
  return (
    <components.SingleValue {...props}>
      <i className={`fas fa-fw ${order.collective_order ? "fa-cubes" : "fa-cube"}`} />{" "}
      {order.order_title}
    </components.SingleValue>
  );
};

class OrderPicker extends React.Component {
  state = {
    selectedOrder: this.props.orders.find(order => order.id === this.props.selectedOrderId)
  };

  handleChange = selectedOrder => {
    this.setState({ selectedOrder });
  };

  // Builds a searchable string for each order, used to compare options against the search input
  getOptionLabel = order => {
    if (!order) {
      return null;
    }

    return [
      order.order_title,
      order.recorder,
      order.collective_order && "Sammelauftrag",
      order.order_date
    ].join(" ");
  };

  // Sets the OrderId is set on the HTML input for form submission
  getOptionValue = order => order.id;

  render() {
    const { orders } = this.props;
    const { selectedOrder } = this.state;

    return (
      <>
        <label className="form-control-label select optional" htmlFor="order_picker">
          Auftrag
        </label>
        <Select
          isSearchable
          isClearable
          isDisabled={this.props.isDisabled}
          options={orders}
          name={this.props.htmlInputName}
          value={selectedOrder}
          getOptionLabel={this.getOptionLabel}
          getOptionValue={this.getOptionValue}
          onChange={this.handleChange}
          components={{ Option, SingleValue }}
          placeholder="Auftrag wählen…"
          noOptionsMessage={() => "Keine Aufträge gefunden."}
          inputId="order_picker"
          ref={select => (window.orderPickerSelect = select)}
        />
      </>
    );
  }
}

export default OrderPicker;
